import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ClickAwayListener, Tooltip } from '@mui/material';

import ActiveCampaignsStatus from '@/components/utility/statuses/ActiveCampaignStatus';
import useBreakpoints from '@/hooks/utility/useBreakpoints';

export default function PlaylistGenres({ genres, centered }: { genres: string[]; centered?: boolean }) {
  const [genreBoxWidth, setGenreBoxWidth] = useState<number>();
  const [genresToDisplay, setGenresToDisplay] = useState<string[]>();
  const [genresInMore, setGenresInMore] = useState<string[]>();
  const { breakpointHitSmall } = useBreakpoints();
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const widthDivision = useMemo(() => {
    if (!genreBoxWidth) return;
    if (breakpointHitSmall) {
      return { division: genreBoxWidth / 9, furtherDivision: genreBoxWidth / 9 - 10 };
    }
    return { division: genreBoxWidth / 8, furtherDivision: genreBoxWidth / 8 - 8 };
  }, [breakpointHitSmall, genreBoxWidth]);

  const getGenreBoxWidth = useCallback(() => {
    const width = document.getElementById('genre-box')?.clientWidth;
    if (!width) return;
    if (breakpointHitSmall) {
      setGenreBoxWidth(width * 2);
    } else {
      setGenreBoxWidth(width);
    }
  }, [breakpointHitSmall]);

  const getGenresCharLength = useMemo(() => {
    if (!genreBoxWidth || !widthDivision) return;
    let genresCharLength = 0;
    let genresWithinLimitLength = 0;
    const genresWithinLimit: string[] = [];
    const genresOutsideLimit: string[] = [];
    genres.forEach((genre) => {
      genresCharLength += genre.length;
      if (genresCharLength < widthDivision.division) {
        genresWithinLimit.push(genre);
        genresWithinLimitLength += genre.length;
      } else {
        genresOutsideLimit.push(genre);
      }
    });
    if (genresOutsideLimit.length > 0) {
      if (genresWithinLimitLength > widthDivision.furtherDivision) {
        const pop = genresWithinLimit.pop();
        if (pop) {
          genresOutsideLimit.unshift(pop);
        }
        if (pop && pop.length < 5) {
          const pop2 = genresWithinLimit.pop();
          if (pop2) {
            genresOutsideLimit.unshift(pop2);
          }
        }
      }
    }
    setGenresToDisplay(genresWithinLimit);
    setGenresInMore(genresOutsideLimit);
  }, [genreBoxWidth, genres, widthDivision]);

  useEffect(() => {
    getGenreBoxWidth();
    getGenresCharLength;
  }, [getGenreBoxWidth, getGenresCharLength]);

  const handleResize = useCallback(() => {
    getGenreBoxWidth();
  }, [getGenreBoxWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <div id="genre-box" className={`${centered ? 'text-center jc-center' : 'text-left'} d-flex flex-wrap gap4`}>
      {genresToDisplay?.map((item, index) => (
        <ActiveCampaignsStatus key={index} text={item} size="small pt0 capitalize" color="genre" />
      ))}
      {genresInMore && genresInMore.length > 0 && (
        <Tooltip
          placement="top"
          arrow
          open={tooltipOpen}
          title={
            <div className="text-center">
              {genresInMore.map((item, index) => (
                <p key={index} className="capitalize small pt0 pb2 text-faded">
                  {item}
                  {index < genresInMore.length - 1 && ','}
                </p>
              ))}
            </div>
          }
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(!tooltipOpen);
            }}
            onMouseOver={() => {
              setTooltipOpen(true);
            }}
            onMouseLeave={() => {
              setTooltipOpen(false);
            }}
          >
            <ClickAwayListener
              onClickAway={() => {
                setTooltipOpen(false);
              }}
            >
              <div>
                <ActiveCampaignsStatus
                  text={`+ ${genresInMore?.length} More`}
                  size="small pt0 underline"
                  color="genre"
                />
              </div>
            </ClickAwayListener>
          </div>
        </Tooltip>
      )}
    </div>
  );
}
