import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, CircularProgress, Icon } from '@mui/material';

import ScrollToTopButton from '@/components/utility/buttons/ScrollToTopButton';
import { PromoteFlowStepFormatter } from '@/formatters/PromoteFlowStepFormatter';
import numberFormatter from '@/formatters/ShortNumberConverter';
import useSubscription from '@/hooks/account/useSubscription';
import useArtist from '@/hooks/artist/useArtist';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSessionContext from '@/hooks/context/useSessionContext';
import usePricing from '@/hooks/pricing/usePricing';
import useUserTracking from '@/hooks/useUserTracking';
import useScrollToTop from '@/hooks/utility/useScrollToTop';
import { PlaylistPitchModel } from '@/models/Playlist';
import { SpotifyChosenPlaylistsModel, SpotifyChosenTracksModel } from '@/models/Spotify';
import PlaylistAPI from '@/network/PlaylistAPI';
import { handleApiError } from '@/utility/api';

import StripeCheckout from '../../payments/StripeCheckout';
import NumberStepper from '../../utility/microcomponents/NumberStepper';
import DialogModal from '../../utility/modals/DialogModal';
import PaymentOptions from '../PaymentOptions';
import PitchChooseSongs from './playlisting-steps/PitchChooseSongs';
import PitchPlaylistRecommendations from './playlisting-steps/PitchPlaylistRecommendations';
import PitchSummary from './playlisting-steps/PitchSummary';

const PlaylistingModal = ({ closeModalOutput }: { closeModalOutput: () => void }) => {
  const { t } = useTranslation();
  const { playlistPitch, playlistPitchFull, isDataLoading } = usePricing();
  const { accountId } = useAccountContext();
  const { scrollToTopInPromoteFlows } = useScrollToTop();
  const userTracking = useUserTracking();
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const { sessionId, setSessionId } = useSessionContext();

  const scrollRef = useRef<HTMLDivElement>(null);

  const { artist } = useArtist();
  const { isSubscribed } = useSubscription();

  const [step, setStep] = useState<number>(1);

  const [tracks, setTracks] = useState<SpotifyChosenTracksModel[]>([]);
  const [playlists, setPlaylists] = useState<SpotifyChosenPlaylistsModel[]>([]);

  const { costPerPitch, price, subscriptionDiscount } = useMemo(() => {
    if (!playlistPitch || !playlistPitchFull) return { costPerPitch: 0, price: 0, subscriptionDiscount: 0 };

    const costPerPitch = playlistPitch.price;
    const price = tracks.length * playlists.length * costPerPitch;

    const discountedPrice = tracks.length * playlists.length * playlistPitchFull.discount.price;
    const fullPrice = tracks.length * playlists.length * playlistPitchFull.standard.price;

    const subscriptionDiscount = fullPrice - discountedPrice;

    return { costPerPitch, price, subscriptionDiscount };
  }, [playlistPitch, playlistPitchFull, tracks, playlists]);

  const [canStep, setCanStep] = useState(false);

  const NUMBER_OF_STEPS = useMemo(() => (isSubscribed ? 3 : 4), [isSubscribed]);

  const [draftCampaignId, setDraftCampaignId] = useState<string>();
  const [loadPayment, setLoadPayment] = useState<boolean>(false);
  const [disablePay, setDisablePay] = useState<boolean>(false);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const defaultValues = {
    accountId: undefined,
    artistId: undefined,
    appleReceipt: undefined,
    playlistPitches: [],
  };

  const formMethods = useForm<PlaylistPitchModel>({ defaultValues });
  const playlistPitches = formMethods.watch('playlistPitches');

  useEffect(() => {
    if (accountId) {
      formMethods.setValue('accountId', accountId);
    }
  }, [accountId, formMethods]);

  useEffect(() => {
    if (artist) {
      formMethods.setValue('artistId', artist.id);
    }
  }, [artist, formMethods]);

  const createPlaylistPitchDraft = useCallback(async () => {
    try {
      if (!accountId || !artist) return;

      const newData = playlistPitches.map((pitch) => {
        return pitch.playlists && pitch.playlists.length > 0
          ? {
              ...pitch,
              totalSpend: pitch.playlists.length * (costPerPitch * 100),
              priceLocale: 'en_GB@currency=GBP',
            }
          : pitch;
      });
      const response = await PlaylistAPI.createPlaylistPitchDraft({
        accountId,
        artistId: artist?.id,
        playlistPitches: newData,
        ...(sessionId && { sessionId: sessionId }),
      });
      setDraftCampaignId(response.data.id);
      setLoadPayment(true);
    } catch (error: unknown) {
      handleApiError({ error });
    }
  }, [accountId, artist, playlistPitches, sessionId, costPerPitch]);

  const handleCanStep = useCallback(
    (value: boolean) => {
      if (value !== canStep) {
        setCanStep(value);
      }
    },
    [canStep]
  );

  const outputTracks = useCallback((tracks: SpotifyChosenTracksModel[]) => {
    setTracks(tracks);
  }, []);

  const outputPlaylists = useCallback((playlists: SpotifyChosenPlaylistsModel[]) => {
    setPlaylists(playlists);
  }, []);

  const handleDialogOutput = (output: boolean) => {
    if (output) {
      closeModalOutput();
      setSessionId(undefined);
      userTracking?.productExited({
        product: 'Playlist Pitch',
        productScreenName: PromoteFlowStepFormatter('playlisting', step),
        tracks: tracks.map((track) => track.name),
        playlists: playlists.map((playlist) => playlist.name),
      });
    } else {
      setDialogOpen(false);
    }
  };

  const stepRef = useRef(step);

  const trackProductViewedWithData = useCallback(() => {
    if (!userTracking) return;
    if (stepRef.current !== step) {
      userTracking?.productViewed?.({
        product: 'Playlist Pitch',
        productScreenName: PromoteFlowStepFormatter('playlisting', step),
        tracks: tracks.map((track) => track.name),
        playlists: playlists.map((playlist) => playlist.name),
      });
      stepRef.current = step;
    } else if (isInitialLoad) {
      userTracking?.productViewed?.({
        product: 'Playlist Pitch',
        productScreenName: PromoteFlowStepFormatter('playlisting', step),
      });
      setIsInitialLoad(false);
    }
  }, [userTracking, tracks, playlists, step, isInitialLoad]);

  useEffect(() => {
    trackProductViewedWithData();
  }, [trackProductViewedWithData]);

  return (
    <div className="promote-modal-container text-center" ref={scrollRef}>
      <ScrollToTopButton inPromoteFlow={true} scrollRef={scrollRef} />
      <NumberStepper steps={NUMBER_OF_STEPS} stepNumber={step} />
      <DialogModal
        open={dialogOpen}
        title={'DIALOGS.QUIT-PLAYLISTING'}
        content={'DIALOGS.ARE-YOU-SURE-YOU-WANT-TO-QUIT'}
        output={(output) => {
          handleDialogOutput(output);
        }}
      />
      <Button
        className="icon-btn close-button"
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        <Icon>close</Icon>
      </Button>
      <div id={'playlisting-container'} className="ml-auto mr-auto mt48 mt20-lg-down mb300 w90p-lg-down w60p">
        <FormProvider {...formMethods}>
          {step === 1 && <PitchChooseSongs outputTracks={outputTracks} canStep={handleCanStep} />}
          {step === 2 && (
            <PitchPlaylistRecommendations
              outputPlaylists={outputPlaylists}
              canStep={handleCanStep}
              playlists={playlists}
            />
          )}
          {step === 3 && <PitchSummary tracks={tracks} playlists={playlists} />}
          {step === 4 && (
            <div className="max-w600 ml-auto mr-auto">
              <h3>Payment</h3>
              <div className="card mt48">
                <PaymentOptions type="playlist" amount={price} discount={subscriptionDiscount} />
              </div>
            </div>
          )}
        </FormProvider>
      </div>
      {tracks?.length > 0 && !isDataLoading && (
        <div className="promote-footer">
          <div className={`card-inner w90p p10 pl20 m-auto d-flex ${playlists?.length > 0 ? 'to-do-card' : ''}`}>
            {tracks?.length > 0 && playlists?.length > 0 && (
              <div className="text-left mt-auto mb-auto">
                <div className="d-flex gap8">
                  <p className="pt8">Total: £{price.toFixed(2)}</p>
                  {isSubscribed && (
                    <div className="percentage-difference-container green mt4">
                      <p className="status-text active">
                        {'You saved £' + numberFormatter(subscriptionDiscount, 2) + ' with pro'}
                      </p>
                    </div>
                  )}
                </div>
                <p className="text-faded small">{`${tracks.length} songs across ${playlists.length} playlists`}</p>
              </div>
            )}
            <div className="ml-auto">
              {step > 1 && (
                <Button
                  disabled={disablePay}
                  className="border-btn"
                  onClick={() => {
                    setStep(step > 1 ? step - 1 : step);
                    scrollToTopInPromoteFlows();
                  }}
                >
                  <Icon className="ml-8">chevron_left</Icon>
                  {t('COMMON.BACK')}
                </Button>
              )}
              {step < NUMBER_OF_STEPS && (
                <Button
                  disabled={!canStep}
                  className="btn-white"
                  data-testid="playlisting-continue-button"
                  onClick={() => {
                    setStep(step < NUMBER_OF_STEPS ? step + 1 : step);
                    scrollToTopInPromoteFlows();
                    trackProductViewedWithData();
                  }}
                >
                  {t('COMMON.CONTINUE')}
                  <Icon className="mr-8">chevron_right</Icon>
                </Button>
              )}
              {step === NUMBER_OF_STEPS && (
                <Button
                  disabled={disablePay}
                  className="btn-white"
                  data-testid="playlisting-confirm-and-pay-button"
                  onClick={() => {
                    setDisablePay(true);
                    createPlaylistPitchDraft();
                  }}
                >
                  {disablePay ? <CircularProgress size={16} /> : t('PLAYLISTING.CONFIRM-AND-PAY')}
                </Button>
              )}
            </div>
            {loadPayment && (
              <StripeCheckout
                product={{
                  draftCampaignId: draftCampaignId,
                  productType: 'Playlist Pitch',
                  priceId: playlistPitch?.priceId || '',
                  platform: 'playlist',
                  orderDetails: {
                    totalValue: Number(price.toFixed(2)),
                    totalQuantity: tracks.length * playlists.length,
                    currency: 'GBP',
                    products: tracks.map(() => ({
                      quantity: playlists.length,
                      productID: isSubscribed ? 'playlist_pitch_001_discounted' : 'playlist_pitch_001',
                      price: costPerPitch,
                      name: 'Playlist Pitch',
                      currency: 'GBP',
                    })),
                  },
                }}
                onComplete={(output?: boolean) => {
                  setLoadPayment(false);
                  setDisablePay(false);
                  if (!output) {
                    closeModalOutput();
                  }
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlaylistingModal;
