import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Modal } from '@mui/material';
import { format } from 'date-fns';

import { SpotifyChosenTracksModel, SpotifyMusicModel } from '@/models/Spotify';

const AlbumTracksModal = ({
  open,
  isSingleSelection,
  onClose,
  album,
  chosenTracks,
  handleUpdateAlbumTrack,
}: {
  open: boolean;
  isSingleSelection?: boolean;
  onClose: () => void;
  album: SpotifyMusicModel;
  chosenTracks: SpotifyChosenTracksModel[];
  handleUpdateAlbumTrack: (albumTracks: SpotifyChosenTracksModel[], trackIdsToRemove: string[]) => void;
}) => {
  const { t } = useTranslation();

  const [chosenAlbumTracks, setChosenAlbumTracks] = useState<SpotifyChosenTracksModel[]>([]);
  const [trackIdsToRemove, setTrackIdsToRemove] = useState<string[]>([]);

  const tracks = useMemo(() => album.tracks, [album]);
  const albumTrackIds = useMemo(() => tracks.map((item) => item.id), [tracks]);
  const chosenTrackIds = useMemo(() => chosenTracks.map((item) => item.id), [chosenTracks]);

  useEffect(() => {
    setChosenAlbumTracks(chosenTracks.filter((item) => albumTrackIds.includes(item.id)));
  }, [albumTrackIds, chosenTracks]);

  const completeAlbumSelection = useCallback(() => {
    handleUpdateAlbumTrack(chosenAlbumTracks, trackIdsToRemove);
    onClose();
  }, [chosenAlbumTracks, handleUpdateAlbumTrack, onClose, trackIdsToRemove]);

  const handleModalClose = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <>
        <div className="to-do-modal">
          <div className="d-flex mb20">
            <div className="d-flex list-item campaign">
              <img src={album?.images[0].url} alt="" />
              <div className="mt-auto mb-auto">
                <p>{album?.name}</p>
                <p className="small text-faded">{format(new Date(album?.release_date), 'yyyy')}</p>
              </div>
            </div>
            <div className="ml-auto cursor-pointer">
              <Icon onClick={onClose}>close</Icon>
            </div>
          </div>
          <div className="max-h400 overflow-scroll pr10">
            {tracks?.map((track) => (
              <div
                key={track.id}
                className="d-flex mb16 cursor-pointer"
                onClick={() => {
                  if (isSingleSelection) {
                    setChosenAlbumTracks([
                      {
                        id: track.id,
                        name: track.name,
                        type: 'album',
                        artists: track.artists,
                        images: album.images,
                        release_date: album.release_date,
                      },
                    ]);
                    return;
                  }
                  if (chosenAlbumTracks.map((item) => item.id).includes(track.id)) {
                    if (chosenTrackIds.includes(track.id)) {
                      setTrackIdsToRemove((value) => [...value, track.id]);
                    }
                    setChosenAlbumTracks(chosenAlbumTracks.filter((item) => item.id !== track.id));
                  } else {
                    if (trackIdsToRemove.includes(track.id)) {
                      setTrackIdsToRemove((value) => value.filter((id) => id !== track.id));
                    }
                    setChosenAlbumTracks([
                      ...chosenAlbumTracks,
                      {
                        id: track.id,
                        name: track.name,
                        type: 'album',
                        artists: track.artists,
                        images: album.images,
                        release_date: album.release_date,
                      },
                    ]);
                  }
                }}
              >
                <div>
                  <p className="small">{track.name}</p>
                  <p className="small text-faded">
                    {track.artists.map((item, index) => `${item.name}${index < track.artists.length - 1 ? ', ' : ''}`)}
                  </p>
                </div>
                <div className="ml-auto mt-auto mb-auto">
                  {chosenAlbumTracks.map((item) => item.id).includes(track.id) ? (
                    <div>
                      <Icon className="text-blue">check_circle</Icon>
                    </div>
                  ) : (
                    <div>
                      <Icon className="material-symbols-outlined text-faded">circle</Icon>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          <Button className="btn-black-2 w100p ml0" onClick={completeAlbumSelection}>
            {t('COMMON.DONE')}
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default AlbumTracksModal;
